.CustomModal{
    border-radius: 10px;
    overflow: hidden;
}

.CustomModal .modal-footer{
    display: flex;
    justify-content: space-evenly;
}

.CustomModal button{
    margin: 0px;
    box-shadow: -2px 2px 3px black;
}

@media (min-width:993px){
    .CustomModal{
        font-size: 1.5rem;
        width: 40%;
    }
}