.ScriptModal{
    border-radius: 10px;
    overflow: auto;
}

.ScriptModal .modal-footer{
    display: flex;
    justify-content: center;
}

.ScriptModal button{
    margin: 0px;
    box-shadow: -2px 2px 3px black;
}
