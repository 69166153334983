.LoginForm{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 20px;
    padding: 20px;
    background-color: white;
    color: #003453;
    border-radius: 15px;
    box-shadow: -2px 2px 3px black;
}

.ToastSuccess{
    background-color: #4CAF50;
  }
  
.ToastFailure{
    background-color: #F44336;
  }

@media (min-width:993px){
    .LoginForm{
        width: 50%;
        margin: 50px 20px;
    }
}