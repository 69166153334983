.ReportControl{
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    color: #003453;
    width: 80%;
    padding: 8px;
}

:global(.react-daterange-picker__inputGroup){
    height: 45px !important;
}

:global(.react-daterange-picker__wrapper){
    border: none !important;
    color: #003453;
}

:global(button.react-daterange-picker__clear-button.react-daterange-picker__button) {
    display: none;
}

.DateRangePicker{
    border-radius: 25px;
    height: 60px;
}

.BackBtn{
    background-color: #003453;
}

.BackBtn a{
    color: white;
}

.ReportTable{
    color: black;
    width: 80%;
    box-shadow: -3px 4px 3px black;
    border-radius: 15px;

}

.ReportTable tr:focus{
    outline: none;
}

.ReportChart{
    margin-top: 40px;
    display: none;
}

.ToastSuccess{
    background-color: #4CAF50;
  }
  
.ToastFailure{
    background-color: #F44336;
  }

@media (min-width:1000px){
    .ReportControl{
        flex-flow: row;
    }
    .ReportChart{
        display: block;
    }
}
